@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter.ttf");
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("./assets/fonts/PlayfairDisplayItalic.ttf");
}

* {
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  hyphens: auto;
  background-color: #2e2e2d;
  color: #ffffff;
}

ul {
  list-style-type: none;
}

a {
  color: #de5050;
  text-decoration: none;
}

a:hover {
  color: #c13535;
}

/** Cursor **/

.cursor {
  display: none;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    opacity: 0;
  }
  .cursor__inner {
    fill: none;
    stroke: #ffffff;
    stroke-width: 1px;
  }
}

/** Slideshow  **/

.message {
  background: var(--color-text);
  color: var(--color-bg);
  padding: 1rem;
  text-align: center;
}

.frame {
  padding: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}

.frame a {
  pointer-events: auto;
}

.frame__title {
  font-size: 1rem;
  margin: 0 0 0.5rem;
  font-weight: normal;
}

.frame__links {
  display: flex;
  margin: 0.5rem 0;
}

.frame__links a {
  margin: 0 0.35rem;
  padding: 0 0.35rem;
  position: relative;
}

.frame__links a:first-child {
  margin-left: 0;
  padding-left: 0;
}

.frame__links a:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 1rem;
  background: var(--color-link);
  position: absolute;
  left: calc(100% + 0.35rem);
  top: calc(50% - 0.5rem);
  pointer-events: none;
}

.frame__subtitle {
  font-weight: normal;
  font-size: 1rem;
  margin: 0.5rem 0 1rem;
}

.frame__indicator {
  background: var(--color-indicator);
  width: 3rem;
  height: 0.5rem;
  transform-origin: 0 50%;
}

.strip-outer {
  width: 100%;
  height: 350px;
  margin: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: none;
}

@media screen and (min-width: 768px) {
  .strip-outer {
    height: 600px;
  }
}

.strip-inner {
  position: relative;
  top: 0;
  /* height: 800px; */
  width: -moz-fit-content;
  width: fit-content;
}

.draggable {
  height: 500px;
  top: calc(50% - 250px);
  width: 100%;
  position: absolute;
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.strip {
  height: 100%;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  position: relative;
  pointer-events: none;
}

.strip__item {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.img-outer {
  --imgheight: 450px;
  height: var(--imgheight);
  width: calc(var(--imgheight) * 0.7);
  overflow: hidden;
  position: relative;
  flex: none;
}

.img-outer--size-s {
  --imgheight: 300px;
}

.img-outer--size-m {
  --imgheight: 360px;
}

.img-outer--size-l {
  --imgheight: 390px;
}

.img-outer--size-xl {
  --imgheight: 420px;
}

.img-inner {
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  left: -20px;
  top: -20px;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
}

.strip__item-number {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.strip__item-link {
  pointer-events: auto;
  cursor: pointer;
  display: block;
  position: relative;
}

.strip__item-link::before {
  content: "";
  position: absolute;
  width: 160%;
  height: 120%;
  left: -30%;
  top: -10%;
}

.strip__item-link span {
  display: block;
}

/* Will-change */
.strip,
.strip__item,
.cursor__side,
.content__close,
.content__item-title,
.content__item-copy {
  will-change: transform;
}

@media (any-pointer: fine) {
  .cursor2 {
    display: block;
  }
  .cursor2 .cursor__inner {
    z-index: 9999;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    border-radius: 50%;
  }
  .cursor2 .cursor__side {
    position: absolute;
    top: 50%;
    width: 5px;
    height: 1px;
    background: #fff;
    opacity: 0;
  }
  .cursor2 .cursor__side--left {
    right: calc(100% + 5px);
  }
  .cursor2 .cursor__side--right {
    left: calc(100% + 5px);
  }
  .cursor2 .cursor__inner--circle {
    width: 25px;
    height: 25px;
    border: 1px solid #fff;
  }
}

/** IMG **/

.content__item-imgwrap {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  will-change: transform;
  background-color: black;
}

.content__item-img {
  --overflow: 40px;
  height: calc(100% + (2 * var(--overflow)));
  top: 0;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: 50% 0%;
  will-change: transform;
}

.content__item-title {
  position: relative;
  line-height: 1;
  will-change: transform;
}

/** MOTION  **/

div.initial {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #171717;
  z-index: 50;
}

div.initial .svg {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 50;
}

.svgText {
  font-size: 34px;
  font-weight: bold;
  text-transform: uppercase;
}

.svgTextScd {
  font-family: "PlayfairDisplay";
  text-transform: lowercase;
}

.pattern {
  color: #ffffff;
}

.patternRect {
  width: 100vw;
  height: 100vh;
  fill: currentColor;
}

.patternMotionRect {
  width: 100vw;
  height: 100vh;
  fill: currentColor;
}
